import React, { useEffect, useState } from "react";
import "./TestimonialList.css";
import axios from "axios";

const TestimonialList = () => {
  const [testimonials, setTestimonials] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null);
  const [modalData, setModalData] = useState({
    ts_name: "",
    ts_description: "",
  });

  const handleDelete = (index) => {
    try {
      axios({
        url: "https://lunarsenterprises.com:6020/aqualillies/delete",
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: {
          testimonial_id: index,
        },
      })
        .then((response) => {
          if (response.data.result) {
            Testimonaials();
          } else {
            console.log("not working in testimonials list");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err, "error");
    }
  };

  const handleEditClick = (item) => {
    setEditingIndex(item);
    setModalData(item);
    setShowForm(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setModalData({ ...modalData, [name]: value });
  };
  useEffect(() => {
    Testimonaials();
  }, []);

  const Testimonaials = () => {
    try {
      axios({
        url: "https://lunarsenterprises.com:6020/aqualillies/list/testimonial",
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.data.result) {
            setTestimonials(response.data.list);
          } else {
            console.log("not working in testimonials list");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err, "error");
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    try {
      axios({
        url: "https://lunarsenterprises.com:6020/aqualillies/add/testimonial",
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: {
          name: modalData.ts_name,
          description: modalData.ts_description,
        },
      })
        .then((response) => {
          if (response.data.result) {
            Testimonaials();
          } else {
            console.log("not working in testimonials list");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err, "error");
    }
    setModalData({ ts_name: "", ts_description: "" });
    setShowForm(false);
  };

  return (
    <div className="testimonial-wrapper">
      <button
        className="add-testimonial-button"
        onClick={() => {
          setShowForm(true);
          setEditingIndex(null);
          setModalData({ ts_name: "", ts_description: "" });
        }}
      >
        Add Testimonial
      </button>
      {console.log(showForm, "showForm")}

      {showForm === true ? (
        <div className="show-modal">
          <form className="add-testimonial-form" onSubmit={handleFormSubmit}>
            <input
              type="text"
              name="ts_name"
              placeholder="Your Name"
              value={modalData.ts_name}
              onChange={handleInputChange}
              required
            />
            <textarea
              name="ts_description"
              placeholder="Your Testimonial"
              value={modalData.ts_description}
              onChange={handleInputChange}
              required
            ></textarea>
            <button type="submit">
              {editingIndex !== null ? "Update" : "Submit"}
            </button>
            <button
              type="button"
              className="close-button"
              onClick={() => setShowForm(false)}
            >
              Cancel
            </button>
          </form>
        </div>
      ) : null}

      <div className="testimonial-list">
        {testimonials.map((item, index) => (
          <div className="testimonial-card" key={item.ts_id}>
            <div className="testimonial-content">
              <h3 className="testimonial-name">{item.ts_name}</h3>
              <p className="testimonial-text">{item.ts_description}</p>
              <div className="testimonial-actions">
                {/* <button
                  className="edit-button"
                  onClick={() => handleEditClick(item)}
                >
                  ✏️
                </button> */}
                <button
                  className="delete-button"
                  onClick={() => handleDelete(item.ts_id)}
                >
                  🗑️
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TestimonialList;
