import React, { useState } from "react";
import { Nav } from "react-bootstrap";
import {
  FaBars,
  FaHome,
  FaUser,
  FaSignOutAlt,
  FaMailBulk,
  FaPhabricator,
} from "react-icons/fa";
import "./Sidebar.css";
import { useNavigate } from "react-router-dom";

function Sidebar() {
  const Navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isHeldOpen, setIsHeldOpen] = useState(false);

  const toggleHoldSidebar = () => {
    setIsHeldOpen(!isHeldOpen);
    setIsOpen(!isHeldOpen);
  };
  const handleLogOut = () => {
    localStorage.removeItem("isLoggedIn");
    Navigate("/");
  };
  return (
    <div
      className={`sidebar bg-dark text-white p-3 ${isOpen ? "open" : "closed"}`}
      onMouseEnter={() => !isHeldOpen && setIsOpen(true)}
      onMouseLeave={() => !isHeldOpen && setIsOpen(false)}
    >
      <div className="d-flex align-items-center justify-content-between">
        {isOpen && <h3 className="heading-sidebar text-center">Aqualillies</h3>}
        <button onClick={toggleHoldSidebar} className="btn btn-dark toggle-btn">
          <FaBars />
        </button>
      </div>

      <Nav className="flex-column mt-3">
        <Nav.Link
          href="/dashboard"
          className="text-white d-flex align-items-center"
        >
          <FaHome className="sidebar-icon" />
          {isOpen && <span className="ms-2">Dashboard</span>}
        </Nav.Link>
        <Nav.Link
          href="/Booking"
          className="text-white d-flex align-items-center"
        >
          <FaUser className="sidebar-icon" />
          {isOpen && <span className="ms-2">Booking </span>}
        </Nav.Link>

        <Nav.Link
          href="/EnquiryList"
          className="text-white d-flex align-items-center"
        >
          <FaMailBulk className="sidebar-icon" />
          {isOpen && <span className="ms-2">Enquires</span>}
        </Nav.Link>
        <Nav.Link
          href="/Testimonials"
          className="text-white d-flex align-items-center"
        >
          <FaUser className="sidebar-icon" />
          {isOpen && <span className="ms-2">Testimonials</span>}
        </Nav.Link>
        <Nav.Link
          href="/gallery"
          className="text-white d-flex align-items-center"
        >
          <FaPhabricator className="sidebar-icon" />
          {isOpen && <span className="ms-2">Gallery</span>}
        </Nav.Link>
        <Nav.Link
          onClick={handleLogOut}
          className="text-white d-flex align-items-center"
        >
          <FaSignOutAlt className="sidebar-icon" />
          {isOpen && <span className="ms-2">Logout</span>}
        </Nav.Link>
      </Nav>
    </div>
  );
}

export default Sidebar;
