import React, { useEffect, useState } from "react";
import "./Gallery.css";
import axios from "axios";

const Gallery = () => {
  const [gallery, setGallery] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null);
  const [image, setImage] = useState(null);

  const handleDelete = (index) => {
    try {
      axios({
        url: "https://lunarsenterprises.com:6020/aqualillies/delete",
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: {
          gallery_id: index,
        },
      })
        .then((response) => {
          if (response.data.result) {
            Gallery();
          } else {
            console.log("not working in testimonials list");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err, "error");
    }
  };

  const handleInputChange = (e) => {
    setImage(e.target.files[0]);
  };
  useEffect(() => {
    Gallery();
  }, []);

  const Gallery = () => {
    try {
      axios({
        url: "https://lunarsenterprises.com:6020/aqualillies/list/gallery",
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.data.result) {
            setGallery(response.data.list);
          } else {
            console.log("not working in testimonials list");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err, "error");
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (!image) {
      console.log("No image selected");
      return;
    }
    try {
      const formData = new FormData();
      formData.append("image", image);
      axios({
        url: "https://lunarsenterprises.com:6020/aqualillies/add/gallery",
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      })
        .then((response) => {
          if (response.data.result) {
            Gallery();
          } else {
            console.log("not working in testimonials list");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err, "error");
    }
    setImage(null);
    setShowForm(false);
  };
  const baseurl = "https://lunarsenterprises.com:6020";
  return (
    <div className="testimonial-wrapper">
      <button
        className="add-testimonial-button"
        onClick={() => {
          setShowForm(true);
          setEditingIndex(null);
          setImage(null);
        }}
      >
        Add Gallery
      </button>

      {showForm === true ? (
        <div className="show-modal">
          <form className="add-testimonial-form" onSubmit={handleFormSubmit}>
            <input
              type="file"
              name="ts_image"
              onChange={handleInputChange}
              required
            />

            <button type="submit">
              {editingIndex !== null ? "Update" : "Submit"}
            </button>
            <button
              type="button"
              className="close-button"
              onClick={() => setShowForm(false)}
            >
              Cancel
            </button>
          </form>
        </div>
      ) : null}

      <div className="testimonial-list">
        {gallery?.map((item, index) => (
          <div className="testimonial-card" key={item.g_id}>
            <div className="testimonial-content">
              <img
                src={baseurl + item.g_image}
                alt="broken image"
                className="gallery-image"
              />
              <div className="testimonial-actions">
                <button
                  className="delete-button"
                  onClick={() => handleDelete(item.g_id)}
                >
                  🗑️
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Gallery;
